import * as i1 from '@ngx-loading-bar/core';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import { HttpContextToken, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { tap, finalize } from 'rxjs/operators';
const NGX_LOADING_BAR_IGNORED = new HttpContextToken(() => false);
class LoadingBarInterceptor {
  constructor(loader) {
    this.loader = loader;
  }
  intercept(req, next) {
    // https://github.com/angular/angular/issues/18155
    if (req.headers.has('ignoreLoadingBar')) {
      if (typeof ngDevMode === 'undefined' || ngDevMode) {
        console.warn(`Using http headers ('ignoreLoadingBar') to ignore loading bar is deprecated. Use HttpContext instead: 'context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)'`);
      }
      return next.handle(req.clone({
        headers: req.headers.delete('ignoreLoadingBar')
      }));
    }
    if (req.context.get(NGX_LOADING_BAR_IGNORED) === true) {
      return next.handle(req);
    }
    let started = false;
    const ref = this.loader.useRef('http');
    return next.handle(req).pipe(tap(() => {
      if (!started) {
        ref.start();
        started = true;
      }
    }), finalize(() => started && ref.complete()));
  }
}
LoadingBarInterceptor.ɵfac = function LoadingBarInterceptor_Factory(ɵt) {
  return new (ɵt || LoadingBarInterceptor)(i0.ɵɵinject(i1.LoadingBarService));
};
LoadingBarInterceptor.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: LoadingBarInterceptor,
  factory: LoadingBarInterceptor.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingBarInterceptor, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.LoadingBarService
    }];
  }, null);
})();
class LoadingBarHttpClientModule {}
LoadingBarHttpClientModule.ɵfac = function LoadingBarHttpClientModule_Factory(ɵt) {
  return new (ɵt || LoadingBarHttpClientModule)();
};
LoadingBarHttpClientModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LoadingBarHttpClientModule
});
LoadingBarHttpClientModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingBarInterceptor,
    multi: true
  }],
  imports: [[HttpClientModule, LoadingBarModule], HttpClientModule, LoadingBarModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingBarHttpClientModule, [{
    type: NgModule,
    args: [{
      imports: [HttpClientModule, LoadingBarModule],
      exports: [HttpClientModule, LoadingBarModule],
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingBarInterceptor,
        multi: true
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LoadingBarHttpClientModule, NGX_LOADING_BAR_IGNORED };
